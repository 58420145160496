import { createRouter, createWebHistory } from 'vue-router';
import TranslationsList from './components/TranslationsList.vue';
import TranslationEdit from './components/TranslationEdit.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: TranslationsList },
    { path: '/edit', component: TranslationEdit },
    { path: '/edit/:translationId', component: TranslationEdit, props: true },
  ]
});

export default router;