<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top" style="width:100%">
    <div class="px-2" style="width:100%">
          <router-link to="/edit" class="btn btn-secondary active">NEW</router-link>
          <a @click="swichShowWordEN()" class="btn active float-end" :class="{ 'btn-secondary': showWordEN, 'btn-light': !showWordEN }"><fa icon="fa-solid fa-flag-usa"></fa></a>
          <a @click="swichShowWordPL()" class="btn active float-end" :class="{ 'btn-secondary': showWordPL, 'btn-light': !showWordPL }"><fa icon="fa-regular fa-flag"></fa></a>
          <a @click="random" class="btn active float-end btn-light"><fa icon="fa-solid fa-shuffle"></fa></a>
          <a @click="ordered" class="btn active float-end btn-light"><fa icon="fa-solid fa-list-ol"></fa></a>
    </div>
  </nav>
  <div class="container words-container">
    <div class="row py-2" v-for="word in data" :key="word.id" @click="word.show = !word.show">
      <div class="col">{{ word.show || showWordPL ? word.pl : '...'}}</div>
      <div class="col">{{ word.show || showWordEN ? word.en : '...' }}</div>
      <div class="col">
        <div class="dropdown float-end">
          <button class="btn btn-light btn-s dropdown-toggle" type="button" :id="'Context' + word.id" data-bs-toggle="dropdown" aria-expanded="false">
            <fa icon="fa-solid fa-bars" />
          </button>
          <ul class="dropdown-menu" :aria-labelledby="'Context' + word.id">
            <li><a class="dropdown-item" href="#" @click="orderUp(word.id)">UP</a></li>
            <li><a class="dropdown-item" href="#" @click="orderDown(word.id)">DOWN</a></li>
            <li><router-link :to="'/edit/' + word.id" class="dropdown-item">Edit</router-link></li>
            <li><a class="dropdown-item" href="#" @click="deleteTranslation(word.id)">Delete</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import db from '@/services/DbFirebase'

export default {
  name: 'App',
  data() {
    return {
      data: [],
      showWordPL: false,
      showWordEN: true,
      isRandom: true
    }
  },
  methods: {

    swichShowWordPL() {
      this.showWordPL = !this.showWordPL;
      for (let ix = 0; ix < this.data.length; ix++) {
        const word = this.data[ix];
        word.show = false;
      }
    },

    swichShowWordEN() {
      this.showWordEN = !this.showWordEN;
      for (let ix = 0; ix < this.data.length; ix++) {
        const word = this.data[ix];
        word.show = false;
      }
    },

    random() {
      this.isRandom = true;
      this.refreshList();
    },

    ordered() {
      this.isRandom = false;
      this.refreshList();
    },

    refreshList() {
      let data = db.getTranslations();
      console.log(this.isRandom);
      console.log(data);


      for (let i = 0; i < data.length; i++) {
        data[i].orderNo2 = this.isRandom ? (Math.random() * 1000) : data[i].orderNo;
        console.log(data[i].orderNo2);
      }
      let newData = data.sort(this._orderTranslations);
      this.data = [];
      console.log(this.data);
      this.data = newData;
    },
    
    deleteTranslation(id) {
      db.deleteTranslation(id);
      this.refreshList();
    },

    orderUp(id) {
      var item = db.getTranslation(id);
      if (!item.orderNo) {
        item.orderNo = 0;
      }
      item.orderNo++;
      item.orderNo++;
      db.updateTranslation(item);
      this.refreshList();
    },

    orderDown(id) {
      let item = db.getTranslation(id);
      if (!item.orderNo) {
        item.orderNo = 0;
      }
      item.orderNo--;
      item.orderNo--;
      db.updateTranslation(item);
      this.refreshList();
    },

    _orderTranslations( a, b ) {
      let orderA = a.orderNo2 ? a.orderNo2 : 0;
      let orderB = b.orderNo2 ? b.orderNo2 : 0;
      if (orderA < orderB){
        return 1;
      }
      if (orderA > orderB){
        return -1;
      }
      return 0;
    }
  },
  mounted() {
    this.refreshList();
  }
}
</script>

<style scope>
  .words-container {
    margin-top:55px;
  }
</style>
