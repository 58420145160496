<template>
    <form @submit.prevent="submitForm" class="m-3" >
        <div class="mb-3">
            <label for="plWord" class="form-label">Polish</label>
            <input id="plWord" name="plWord" type="text" class="form-control" v-model.trim="formObj.pl" />
        </div>
        <div class="mb-3">
            <label for="enWord" class="form-label">English</label>
            <input id="enWord" name="enWord" type="text" class="form-control" v-model.trim="formObj.en" />
        </div>
        <div class="mb-3">
            <label for="enWord" class="form-label">Order no.</label>
            <input id="enWord" name="enWord" type="number" class="form-control" v-model.trim="formObj.orderNo" />
        </div>
        
        <div v-if="formIsValid && canShowFormValidInfo" class="error">Form is not valid</div>

        <button type="submit" class="btn btn-primary mt-3">Save</button>
    </form>
</template>

<script>

import db from '@/services/DbFirebase'

export default {
    props: ['translationId'],
    data() {
        return {
            formObj: {
                id: -1,
                pl: '',
                en: '',
                orderNo: 0
            },
            isNewTranslation: true,
            formIsValid: false,
            canShowFormValidInfo: false
        }
    },
    methods: {
        submitForm() {
            this.canShowFormValidInfo = true;
            this.formIsValid = this.formObj.pl && this.formObj.en;
            if (!this.formIsValid) {
                return;
            }
            
            if (!this.isNewTranslation) {
                db.updateTranslation(this.formObj);
            }
            else {
                db.addTranslation(this.formObj);
            }
            this.$router.push('/');
        }
    },
    created() {
        const dbValue = db.getTranslation(this.translationId);
        if (dbValue) {
            this.formObj = dbValue;
            this.isNewTranslation = false;
        }
        else {
            const data = db.getTranslations();
            let maxOrderNo = 0; 
            let newId = 1; 
            data.forEach((item) => {
                maxOrderNo = Math.max(maxOrderNo, item.orderNo ? item.orderNo : 0);
                newId = Math.max(newId, item.id ? item.id : 0);
            })
            maxOrderNo++;
            newId++;
            this.formObj.orderNo = maxOrderNo;
            this.formObj.id = newId;
        }
    }
}
</script>

<style>

</style>