<script>
export default new class db {
    constructor() {

        this._data = localStorage.getItem('TRANSLATIONS');
        console.log(this._data);
        if (!this._data) {
            this._data = [];
        }
        else {
            this._data = JSON.parse(this._data);
        }

        this._data2 = [
            { id: 1, pl: 'PL1', en: 'EN1', orderNo: 4 },
            { id: 2, pl: 'PL2', en: 'EN2', orderNo: 2 },
            { id: 3, pl: 'PL3', en: 'EN3' },
            { id: 4, pl: 'PL4', en: 'EN4' },
            { id: 5, pl: 'PL5', en: 'EN4' },
            { id: 6, pl: 'PL6', en: 'EN4' },
            { id: 7, pl: 'PL7', en: 'EN4' },
            { id: 8, pl: 'PL8', en: 'EN4' },
            { id: 9, pl: 'PL9', en: 'EN4' },
            { id: 10, pl: 'PL10', en: 'EN4' },
            { id: 11, pl: 'PL11', en: 'EN4' },
            { id: 12, pl: 'PL12', en: 'EN4' },
            { id: 13, pl: 'PL13', en: 'EN4' },
            { id: 14, pl: 'PL14', en: 'EN4' },
            { id: 15, pl: 'PL15', en: 'EN4' },
            { id: 16, pl: 'PL16', en: 'EN4' },
            { id: 17, pl: 'PL17', en: 'EN4' },
            { id: 18, pl: 'PL18', en: 'EN4' },
            { id: 19, pl: 'PL19', en: 'EN4' },
            { id: 20, pl: 'PL20', en: 'EN4' },
            { id: 21, pl: 'PL21', en: 'EN4' },
            { id: 22, pl: 'PL22', en: 'EN4' },
            { id: 23, pl: 'PL23', en: 'EN4' },
            { id: 24, pl: 'PL24', en: 'EN4' },
            { id: 25, pl: 'PL25', en: 'EN4' },
            { id: 26, pl: 'PL26', en: 'EN4' },
            { id: 27, pl: 'PL27', en: 'EN4' },
        ];
    }

    _saveData = () => {
        localStorage.setItem('TRANSLATIONS', JSON.stringify(this._data));
    }

    getTranslations = () => {
        return this._data;
    }

    getTranslation = (id) => {
        for (let ix = 0; ix < this._data.length; ix++) {
            if (this._data[ix].id == id) {
                return this._data[ix];
            }
        }        
    }

    addTranslation = (item) => {
        this._data.push(item);
        this._saveData();
    }

    updateTranslation = (item) => {
        for (let ix = 0; ix < this._data.length; ix++) {
            if (item.id == this._data[ix].id) {
                this._data[ix] = item;
                this._saveData();
                return;
            }
        }
    }

    deleteTranslation = (id) => {
        for (let ix = 0; ix < this._data.length; ix++) {
            if (this._data[ix].id  == id) {
                this._data.splice(ix, 1);
                this._saveData();
                return;
            }
        }
    }
    
}
</script>