import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router.js';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas)
library.add(far)
library.add(fab)

const app = createApp(App)
    .component('fa', FontAwesomeIcon);
    
app.use(router);

app.mount('#app');